import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, defaultProps} from 'react-props-decorators';
import _ from 'lodash';

import {connect} from 'react-redux';

import './MapRetrospective.less';
import {getRetrospective, getTripList, getParkingList, getStopList} from "store/reducers/monitoring/monitoring";
import moment from "moment";
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import formats from "dictionaries/formats";
import currentUser from "helpers/current-user";
import {getBnsoList, getBnsoPeriods} from "store/reducers/vehicles/bnso";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    LabelList,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip as RechartTooltip,
    XAxis,
    YAxis,
    Text,
    AreaChart,
    Area,
    Legend
} from "recharts";
import Popup from "components/ui/popup";
import Checkbox from "components/ui/form/checkbox";
import Block from "components/ui/form/block";
import Input from "components/ui/form/input";
import Radio from "components/ui/form/radio";
import {getTasks} from "store/reducers/kurs/tasks";
import {Link} from "react-router";
import ContextTooltip from "components/ui/context-tooltip";
import {getEntityNames} from "store/reducers/system";
import {EntityList} from "helpers/entity";
import {Slider as UISlider} from "components/ui/slider";
import MapRetrospectiveTooltip from "components/modules/maps/Retrospective/Tooltip/MapRetrospectiveTooltip";
import {getRouteVariant} from "store/reducers/routes/route_variants";
import classNames from 'classnames';
import GlobalLoaderComponent from "components/ui/global-loader";
import ShowDeleted from "components/ui/show-deleted";
import MapRetrospectiveTelematicsTooltip
    from "components/modules/maps/Retrospective/Tooltip/MapRetrospectiveTelematicsTooltip";
import {getMileage} from "../../../../store/reducers/maps";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
const {DistancesRequest, DistancesResponse} = require('../../../../helpers/pb/distanceapi_pb.js');
const {DistanceClient} = require('../../../../helpers/pb/distanceapi_grpc_web_pb');


const Handle = Slider.Handle;
let flag = false;

const GRAPHIC_TYPE_LEVEL = 'level';
const GRAPHIC_TYPE_GRADIENT = 'gradient';
const GRAPHIC_TYPE_DISCRETE = 'bool';
const GRAPHIC_TYPE_HIDDEN = 'hidden';

let defaultConfig = {
    ignition: GRAPHIC_TYPE_DISCRETE,
    speed: GRAPHIC_TYPE_LEVEL,
    satellites: GRAPHIC_TYPE_LEVEL,
    telematics: GRAPHIC_TYPE_LEVEL,
};

@propTypes({
    component: PropTypes.string.isRequired,
    vehicleUuid: PropTypes.string.isRequired,
    bnsoNumber: PropTypes.string.isRequired,
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
    currentTime: PropTypes.object.isRequired,
    onCurrentTimeChange: PropTypes.func.isRequired,
    togglePlay: PropTypes.func.isRequired,
    toggleSpeed: PropTypes.func.isRequired,
    playing: PropTypes.bool.isRequired,
    speed: PropTypes.number.isRequired,
    history: PropTypes.array,
})

@connect(state => ({}), {
    getRetrospective,
    getTripList,
    getParkingList,
    getStopList,
    getBnsoList,
    getEntityNames,
    getRouteVariant,
    getBnsoPeriods,
    getMileage
}, null, {withRef: true})

export default class MapRetrospective extends Component {

    state = {
        data: [],
        ports: [],
        time_gap_limit: 5,
        graphic_step: 100,
        telematics_time: 5,
        config: _.clone(defaultConfig),
        configActive: false,
        related: new EntityList,
        factToggle: false,
        loading: false,
        mileageFrom: 0,
        mileageTo: 0,
        mileage: 0,
    };

    loadCount = 0;

    basePorts = {
        ignition: 'Зажигание',
        speed: 'Скорость',
        satellites: 'Сигнал GPS/ГЛОНАСС',
        //telematics: 'Телематика',
    };

    infoTable = {
        distance: 'Пробег'
    }


    componentDidMount() {
        currentUser.user.is_TelematicLineRetrospective ? this.basePorts.telematics = 'Телематика' : null;
        this.loadData(this.props);
        this.loadBnsoPorts(this.props.vehicleUuid);
        this.loadRelated(this.props);
        const map = this.props.refs.map.refs.map.getWrappedInstance().map;
        this.layerGroupMarkers = L.layerGroup().addTo(map);

    }

    componentWillUpdate(props) {
        const previous = {
            from: moment(this.props.from).utc().format(formats.DATETIME_API),
            to: moment(this.props.to).utc().format(formats.DATETIME_API),
            vehicleUuid: this.props.vehicleUuid,
            historyLength: this.props.history.length,
            cleanupSpecialist: this.props.cleanupSpecialist,
        };
        const current = {
            from: moment(props.from).utc().format(formats.DATETIME_API),
            to: moment(props.to).utc().format(formats.DATETIME_API),
            vehicleUuid: props.vehicleUuid,
            historyLength: props.history.length,
            cleanupSpecialist: props.cleanupSpecialist,
        };

        if (!_.isEqual(previous, current)) {
            this.loadData(props);
        }

        if (props.vehicleUuid !== this.props.vehicleUuid) {
            this.loadBnsoPorts(this.props.vehicleUuid);
        }

        const routeVariants = _.map(props.orderExecutions, 'route_variant_uuid');
        const oldRouteVariants = _.map(this.props.orderExecutions, 'route_variant_uuid');
        if (_.difference(routeVariants, oldRouteVariants).length > 0) {
            this.loadRelated(props);
        }

        if (props.component === 'road') {
            const drivers = _.map(_.flatten(_.map(props.tasks, 'resources')), 'driver_uuid');
            const oldDrivers = _.map(_.flatten(_.map(this.props.tasks, 'resources')), 'driver_uuid');
            if (_.difference(drivers, oldDrivers).length > 0) {
                this.loadRelated(props);
            }
        }
    }

    async getBnsoNumber(vehicleUuid) {
        const response = await this.props.getBnsoPeriods({
            vehicle_uuid: vehicleUuid,
            date_to: this.props.to,
            date_from: this.props.from,
            timestamp: this.props.from,
        });

        if (response.isOk) {
            return _.get(_.first(response.payload.items || []), 'bnso_number');
        }

        return null;
    }

    async loadBnsoPorts(vehicleUuid) {
        const bnsoNumber = await this.getBnsoNumber(vehicleUuid);
        if (!bnsoNumber) return;
        const response = await this.props.getBnsoList({
            filters: {
                withBnsoNumber: bnsoNumber,
            },
        });

        if (response.isOk) {
            const bnso = _.first(response.payload.items);
            const bnsoType = _.get(this.props.bnsoTypes, _.get(bnso, 'bnso_type'));
            const basePorts = bnsoType ? JSON.parse(bnsoType.ports || '[]') : [];

            const ports = _.filter(_.get(bnso, 'ports') || [], (port) => {
                return _.find(basePorts, {number: port.number});
            });

            let config = this.state.config;
            _.each(ports, (port) => {
                if (!port.bnso_indicator_uuid) {
                    return;
                }

                const type = (port.type === 'numeric' && _.get(port, 'graduation.spreadsheets', []).length === 0) ? GRAPHIC_TYPE_LEVEL : GRAPHIC_TYPE_DISCRETE;
                config[port.bnso_indicator_uuid] = type;
                defaultConfig[port.bnso_indicator_uuid] = type;
            });

            this.setState({
                ports,
                config,
            });
        } else {
            response.showErrors();
        }
    }

    async loadData(props) {
        if (this.layerGroupMarkers) {
            this.layerGroupMarkers.clearLayers();
        }
        let diffData = moment(props.from).utc() > moment(props.to).utc();
        let {from, to, vehicleUuid, bnsoNumber, cleanupSpecialist, historyType} = props;

        if (historyType === 'cleanup_specialist') {
            if (!from || !to || !cleanupSpecialist || diffData) {
                //if (!from || !to || !vehicleUuid) {
                return;
            }
        } else if (!from || !to || !vehicleUuid || diffData) {
            //if (!from || !to || !vehicleUuid) {
            return;
        }

        this.loadCount++;
        const loadCount = _.clone(this.loadCount);

        this.setState({loading: true});

        if (window.RNIS_SETTINGS.history_stops_show && bnsoNumber) {
            const stopsResponse = await this.props.getStopList({
                device_code: [bnsoNumber],
                date_from: Number(from.format('X')),
                date_to: Number(to.format('X')),
            });

            if (stopsResponse.payload && stopsResponse.payload.data) {
                this.findPointsCoord(stopsResponse.payload.data, 'stops')
            }
        }

        if (window.RNIS_SETTINGS.history_parking_show && bnsoNumber) {
            setTimeout(async () => {
                const parkingResponse = await this.props.getParkingList({
                    device_code: [bnsoNumber],
                    date_from: Number(from.format('X')),
                    date_to: Number(to.format('X')),
                });

                if (parkingResponse.payload.data) {
                    this.findPointsCoord(parkingResponse.payload.data, 'parking')
                }
            }, 1500);
        }

        const filters = {
            from: from.format(formats.DATETIME_API),
            to: to.format(formats.DATETIME_API),
            step: Math.floor(to.diff(from, 'seconds') / this.state.graphic_step),
        }

        if (historyType === 'cleanup_specialist') {
            filters.devices = [cleanupSpecialist];
        } else {
            filters.vehicle_uuid = vehicleUuid
        }

        const response = await this.props.getRetrospective(filters);

        if (loadCount !== this.loadCount) {
            return;
        }

        this.setState({loading: false});

        if (response.isOk) {
            this.setState({
                data: response.payload.items,
            });
        } else {
            response.showErrors();
        }

        if (bnsoNumber && window.RNIS_SETTINGS.distance_in_history_player_grpc) {
            const url = 'https://' + window.RNIS_SETTINGS.RNIS_API_URL
            //const url = 'http://10.19.124.67:6080'

            const distanceService = new DistanceClient(url);

            const metadata = {
                'x-service': 'distance',
                'token': JSON.parse(localStorage.getItem('user')).token
            };

            const request = new DistancesRequest();
            request.addDeviceCode(bnsoNumber)
            request.setDateFrom(Number(from.format('X')))
            request.setDateTo(Number(to.format('X')))

            distanceService.getDistances(request, metadata, (err, response) => {
                const responseObj = response ? response.toObject().dataList[0] : null;
                if (responseObj) {
                    this.setState({
                        mileage: responseObj.distance /1000
                    })
                }
            });
        }

        if (bnsoNumber && window.RNIS_SETTINGS.distance_in_history_player) {
            let mileageFrom = await this.props.getMileage(
                bnsoNumber,
                from.format(formats.DATETIME_API)
            );

            if (mileageFrom.isOk) {
                this.setState({
                    mileageFrom: mileageFrom.payload.mileage
                })

                let mileageTo = await this.props.getMileage(
                    bnsoNumber,
                    to.format(formats.DATETIME_API)
                );
                if (mileageTo.isOk) {
                    this.setState({
                        mileageTo: mileageTo.payload.mileage
                    })
                } else {
                    response.showErrors();
                }

            } else {
                response.showErrors();
            }
        }
    }


    findPointsCoord(pointsArray, type) {
        pointsArray.map(el => {
            if (el.longitude && el.latitude) {
                let markerCrossData = new L.LatLng(el.latitude, el.longitude);
                let popupText = this.createHTMLPopup({time: el.date_to, from: el.date_from, to: el.date_to, type: type});

                let size = 32;
                let markerCross = new L.Marker(markerCrossData, {
                    icon: L.divIcon({
                        className: `marker-${type}`,
                        iconSize: new L.Point(size, size),
                        iconAnchor: new L.Point(size / 2, size / 2),
                        html: `<div><span class="marker__icon icon-${type}"/></div>`
                    })
                });
                markerCross.id = `${type}${_.uniqueId("id_")}`;
                markerCross.addTo(this.layerGroupMarkers);
                markerCross.bindPopup(popupText);
            }
        })
    }

    createHTMLPopup(data) {
        const {from, to, type} = data;
        return `<div style="padding: 30px 10px 10px; line-height: 5px;"><h2>${type === 'parking' ? 'Стоянка' : 'Остановка'}</h2> <p>c ${moment.unix(from).format(formats.TIME_FULL)} по ${moment.unix(to).format(formats.TIME_FULL)}</p></div>`
    }

    simplifyHistory(history, result) {
        history.forEach(el => {
            result[el.time].values = [
                {
                    type: "ignition",
                    value: el.ignition
                },
                {
                    type: "speed",
                    value: el.speed
                },
                {
                    type: "satellites",
                    value: el.satellites
                }
            ];
        });
        return result;
    }

    simlifyTestV2(history, step = 60) {
        // коэффициент плотности точек на графике
        //let coef = window.RNIS_SETTINGS.HISTORYPOINTSCOEF || 0.65;
        // step = (parseInt(step * coef));

        if (!history.length) {
            return [];
        }
        let {from, to} = this.props;
        // выбранный пользователем период времени в массиве unix timestep с шагом 1 секунда
        let fullPeriod = {};
        let i = Number(moment(from).format('X'));
        let j = Number(moment(to).format('X'));
        // если время только вводится пользователем, то можеь возвращаться некорректная дата
        if (isNaN(i) || isNaN(j)) {
            return []
        }
        // заполняем период секундными интервалами
        while (i < j) {
            fullPeriod[i] = {time: i, values: []};
            i++;
        }
        // обогащаем историческими данными объект
        let richResult = this.simplifyHistory(history, fullPeriod);
        // конвертируем в массив
        let richResultArray = Object.values(richResult);
        // делаем выборку и оставляем только каждый step элемент массива
        let filteredRichResultArray = richResultArray.filter((el, idx) => {
            return idx % step === 0;
        });


        _.forEach(filteredRichResultArray, (el => {
            // если нет значений, то нужно посмотреть были ли в течение step секунд данные и подставить их значение
            if (!el.values.length) {
                let i = 0;
                while (i < step) {
                    let key = el.time + i;
                    if (richResult[key] && richResult[key].values.length) {
                        el.values = richResult[key].values;
                        break;
                    } else {
                        el.values = []
                    }
                    i++;
                }
            }
            el.time = moment.unix(el.time).format(formats.DATETIME_API);
            return el;
        }));
        // заполнение "пустых" зажиганий
        this.emptyArray(filteredRichResultArray);
        return filteredRichResultArray
    }

    // убираем прострелы отсутствующих зажиганий
    emptyArray(arr) {
        let state = []; //массив состояний
        let falseIgnition = []; // массив индексов где зажигание = 0
        arr.forEach(function callback(el, idx) {
            if (el.values.length === 0) {
                // пустой массив
                state.push(2);
            } else if (el.values[0].value === 1) {
                // было зажигание
                state.push(1);
            } else {
                // не было зажигания
                state.push(0);
                falseIgnition.push(idx);
            }
        });

        for (let i = 0; i < falseIgnition.length - 1; i++) {
            for (let k = falseIgnition[i]; k < falseIgnition[i + 1] - 1; k++) {
                if (state[k + 1] === 2) {
                    state[k + 1] = 0;
                    arr[k + 1].values = [
                        {
                            "type": "ignition",
                            "value": 0
                        },
                        {
                            "type": "speed",
                            "value": 0
                        },
                        {
                            "type": "satellites",
                            "value": arr[k].values[2].value
                        }
                    ]
                }
            }
        }
        return arr;
    }

    async loadRelated(props) {
        let itemsToLoad = [];
        if (props.component === 'road') {
            let items = [];
            _.each(props.tasks, (task) => {
                _.each(task.items_fact || task.items, (item) => {
                    if (item.geometry_type === 'road_part') {
                        items.push({
                            class: 'App\\Model\\RoadPart',
                            uuid: _.get(item, 'geometry.0.item_uuid'),
                            source: 'kurs',
                        });
                    } else if (item.geometry_type === 'stop_point') {
                        items.push({
                            class: 'App\\Model\\StopPoint',
                            uuid: _.get(item, 'geometry.0.item_uuid'),
                            source: 'geo',
                        });
                    } else if (item.geometry_type === 'road_repair_part') {
                        items.push({
                            class: 'App\\Dictionaries\\Kurs\\KursRoadRepairParts\\Model',
                            uuid: _.get(item, 'geometry.0.item_uuid'),
                            source: 'dictionary',
                        });
                    }
                });
            });

            itemsToLoad = items;
        } else {
            const routeVariants = _.map(_.uniq(_.filter(_.map(props.orderExecutions, 'route_variant_uuid'))), (uuid) => ({
                class: 'App\\Model\\RouteVariant',
                uuid: uuid,
                source: 'geo',
            }));

            const stopPoints = _.map(_.uniq(_.filter(_.map(_.flatten(_.map(props.orderExecutions, 'data')), 'type_uuid'))), (uuid) => ({
                class: 'App\\Model\\StopPoint',
                uuid: uuid,
                source: 'geo',
            }));

            itemsToLoad = _.concat(routeVariants, stopPoints);
        }

        const response = await this.props.getEntityNames(itemsToLoad);
        if (response.isOk) {
            this.state.related.add(response);
            this.forceUpdate();
        }
    }

    timeToInt(time) {
        return time.unix();
    }

    intToTime(int) {
        return moment.unix(int).format(formats.DATETIME_SHORT);
    }

    intToTimeFull(int) {
        return moment.unix(int).format(formats.DATETIME);
    }

    getSliderHandle(props) {
        const {value, dragging, index, ...restProps} = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={this.intToTimeFull(value)}
                visible={dragging}
                placement="bottom"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    getSliderMarks(min, max, step = 60) {
        let result = {};
        for (let i = min; i <= max; i += step) {
            result[i] = (
                <span>
                    {moment.unix(i).format('DD.MM')}
                    <br/>
                    {moment.unix(i).format('HH:mm')}
                </span>
            );
        }
        return result;
    }

    toggleConfig() {
        this.setState({
            configActive: !this.state.configActive,
        });
    }

    isMapDisplayParts() {
        return this.state.mapDisplayParts;
    }

    render() {
        const isDataExists = this.state.data.filter(({values}) => values && values.length);
        if (isDataExists && !this.props.history.length && this.state.data.length && !isDataExists.length) {
            return <div>
                <div style={{textAlign: 'center', color: '#f65d50', fontSize: "20px", marginTop: '60px'}}>Нет данных по истории перемещения</div>
            </div>
        }
        const {from, to, currentTime} = this.props;
        if (!from || !to || !currentTime) {
            return null;
        }

        const step = Math.round((this.timeToInt(to) - this.timeToInt(from)) / 20);
        if (isNaN(step) || (step == 0)) {
            return null;
        }
        if (isNaN(this.timeToInt(currentTime))) {
            return null;
        }

        const basePorts = this.props.historyType === 'cleanup_specialist' ? {
            speed: 'Скорость',
            satellites: 'Сигнал GPS/ГЛОНАСС',
        } : this.basePorts

        const basePortsMap = _.map(basePorts, ::this.renderBasePort);
        const statePortsMap = _.map(this.state.ports, ::this.renderBnsoPort);
        const mechanisms = _.concat(basePortsMap, statePortsMap);

        return (
            <div className={classNames({
                'history-player': true,
                'history-player_multi': this.props.isHistoryMulti,
                'fullscreen': this.props.fullscreen,
            })}>
                {this.state.loading ? (
                    <GlobalLoaderComponent/>
                ) : null}
                <div className="zoom" onClick={this.props.onFullscreenToggle}>
                    <i className="player__icon player__icon_zoom"/>
                </div>
                <div className="history-graphics_idle">
                    {this.renderIdlePeriods()}
                    {this.renderCurrentTime()}
                </div>
                <div className="history-graphics history-graphic__broad-player">
                    {(mechanisms.length > 0) ? (
                        <div className="history-category">{this.props.historyType === 'cleanup_specialist' ? 'Работа сотрудника' : 'Работа оборудования'}</div>
                    ) : null}
                    {mechanisms}
                    {((this.props.tasks || []).length > 0 && (this.props.orderExecutions || []).length > 0) ? (
                        <div className="history-category">Работа в наряде</div>
                    ) : null}
                    {this.renderTasks()}
                    {this.renderOrderExecutions()}
                    {this.props.historyType !== 'cleanup_specialist' ? this.renderMileage() : null}
                </div>


                <div className="broad-player">
                    <div className="hamburger" onClick={::this.toggleConfig}/>
                    {this.renderConfig()}
                    <div className="switch switch__left">
                        <div className="back-bouble" onClick={this.props.prevTimeX2}>
                            <i className="player__icon player__icon_angle-double-left"/>
                        </div>
                        <div className="back" onClick={this.props.prevTime}>
                            <i className="player__icon player__icon_angle-left"/>
                        </div>
                    </div>
                    <div className="play" onClick={this.props.togglePlay}>
                        <i className={classNames({
                            player__icon: true,
                            player__icon_play: !this.props.playing,
                            player__icon_pause: this.props.playing,
                        })}/>
                    </div>
                    <div className="speed" onClick={this.props.toggleSpeed}>
                        <i className="player__icon player__icon_speed"/>
                        <div className="speed__value">
                            {this.props.speed}x
                        </div>
                    </div>
                    <div className="slider">
                        <Slider
                            value={this.timeToInt(currentTime)}
                            min={this.timeToInt(from)}
                            max={this.timeToInt(to)}
                            step={1}
                            marks={this.getSliderMarks(this.timeToInt(from), this.timeToInt(to), step)}
                            handle={::this.getSliderHandle}
                            onChange={this.props.onCurrentTimeChange}
                        />
                    </div>
                    <div className="switch switch__right">
                        <div className="back-bouble" onClick={this.props.nextTimeX2}>
                            <i className="player__icon player__icon_angle-double-right"/>
                        </div>
                        <div className="back" onClick={this.props.nextTime}>
                            <i className="player__icon player__icon_angle-right"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderBasePort(label, value) {
        return this.renderPort(value, value, label);
    }

    renderBnsoPort(port, index) {
        if (!port.bnso_indicator_uuid) {
            return null;
        }

        let title = _.get(_.get(this.props.bnsoIndicators, port.bnso_indicator_uuid), 'name');
        const graphicType = _.get(this.state.config, port.bnso_indicator_uuid);
        if (graphicType === GRAPHIC_TYPE_GRADIENT) {
            title += ' (градиент)';
        }

        if (port.type === 'numeric' && _.get(port, 'graduation.spreadsheets', []).length === 0) {
            const indicatorMeasure = _.get(_.get(this.props.bnsoIndicators, port.bnso_indicator_uuid), 'measure');

            title += ', ' + indicatorMeasure;
            if (graphicType === GRAPHIC_TYPE_GRADIENT) {
                title += '/ч';
            }
        }

        return this.renderPort(port.bnso_indicator_uuid, index, title, port);
    }

    renderPort(type, index, title, port = null) {
        const graphicType = _.get(this.state.config, type);
        if (graphicType === GRAPHIC_TYPE_HIDDEN) {
            return;
        }

        return (
            <div key={`${type}:${index}`} className="history-graphic">
                <div className="history-graphic_title">
                    {title}
                </div>
                <div className="history-graphic_body">
                    {this.renderGraphic(type, title, graphicType, port)}
                </div>
            </div>
        );
    }

    renderTelematicsGraphic(title) {
        let levelData = _.map(this.props.history, (item) => {
            let packet = {
                timestamp: item.time,
                received: item.receivedTime,
                latitude: item.lat,
                longitude: item.lng,
            };
            if (Math.abs(item.time - item.receivedTime) > this.state.telematics_time) {
                packet.value2 = 0;
                return packet;
            }

            packet.value = 0;
            return packet;
        });

        levelData.unshift({
            timestamp: this.props.from.utc().unix(),
        });
        levelData.push({
            timestamp: this.props.to.utc().unix(),
        });

        return (
            <ResponsiveContainer width="100%" height="137%">
                <LineChart data={levelData}>
                    <XAxis dataKey="timestamp" type="number" domain={['dataMin', 'dataMax']}
                           tickFormatter={(tick) => {
                               return '';
                           }}/>
                    <RechartTooltip content={<MapRetrospectiveTelematicsTooltip/>}
                                    labelFormatter={(label) => {
                                        return moment.unix(label).format(formats.DATETIME_SHORT);
                                    }}/>
                    <Line dataKey="value" isAnimationActive={false} stroke="#fff" name={title}
                          type="monotone"/>
                    <Line dataKey="value2" isAnimationActive={false} stroke="#fff" fill="#ff0000" name={title}
                          type="monotone"/>
                </LineChart>
            </ResponsiveContainer>
        );
    }

    renderGraphic(type, title, graphicType, port = null) {
        if (type === 'telematics') {
            return this.renderTelematicsGraphic(title);
        }

        switch (graphicType) {
            case GRAPHIC_TYPE_LEVEL:
                const levelData = _.filter(_.map(this.state.data, (item) => {
                    let value = _.get(_.find(item.values, {
                        type,
                    }), 'value');

                    if (value === undefined) {
                        value = null;
                    }

                    return {
                        timestamp: moment(item.time).unix(),
                        value,
                    };
                }));

                return (
                    <ResponsiveContainer width="100%" height="137%">
                        <LineChart data={levelData}>
                            <XAxis dataKey="timestamp" type="number" domain={['dataMin', 'dataMax']}
                                   tickFormatter={(tick) => {
                                       return '';
                                   }}/>
                            <RechartTooltip labelFormatter={(label) => {
                                return moment.unix(label).format(formats.DATETIME_SHORT);
                            }}/>
                            <Line dataKey="value" isAnimationActive={false} stroke="#fff" name={title}
                                  type="monotone"/>
                        </LineChart>
                    </ResponsiveContainer>
                );
            case GRAPHIC_TYPE_GRADIENT:
                const levelDataGradient = _.map(this.state.data, (item, index) => {
                    let value = null;
                    if (index > 0) {
                        const prevItem = _.get(this.state.data, index - 1);
                        if (prevItem) {
                            const timeDiff = (moment(item.time).unix() - moment(prevItem.time).unix()) / 3600;
                            const currentValue = _.get(_.find(item.values, {
                                type,
                            }), 'value');
                            const prevValue = _.get(_.find(prevItem.values, {
                                type,
                            }), 'value');

                            if (currentValue !== undefined && prevValue !== undefined) {
                                value = _.round((currentValue - prevValue) / timeDiff, 2);
                            }
                        }
                    }

                    return {
                        timestamp: moment(item.time).unix(),
                        value,
                    };
                });

                return (
                    <ResponsiveContainer width="100%" height="137%">
                        <LineChart data={levelDataGradient}>
                            <XAxis dataKey="timestamp" type="number" domain={['dataMin', 'dataMax']}
                                   tickFormatter={(tick) => {
                                       return '';
                                   }}/>
                            <RechartTooltip labelFormatter={(label) => {
                                return moment.unix(label).format(formats.DATETIME_SHORT);
                            }}/>
                            <Line dataKey="value" isAnimationActive={false} stroke="#fff" name={title}
                                  type="monotone"/>
                        </LineChart>
                    </ResponsiveContainer>
                );
            case GRAPHIC_TYPE_DISCRETE:
                let linesCount = 2;
                let colors = [
                    '#ff0000',
                    '#00ff00',
                ];
                let names = [
                    'Выкл',
                    'Вкл',
                ];
                if (port) {
                    if (_.get(port.graduation, 'spreadsheets', []).length > 0) {
                        linesCount = _.get(port.graduation, 'spreadsheets', []).length;
                        colors = _.map(_.get(port.graduation, 'spreadsheets', []), 'color');
                    }
                    names = JSON.parse(_.get(_.get(this.props.bnsoIndicators, port.bnso_indicator_uuid), 'statuses') || '[]');
                }

                let linesData = [];

                for (let i = 0; i < this.state.data.length - 1; i++) {
                    const item = this.state.data[i];
                    const nextItem = this.state.data[i + 1];
                    /*if (nextItem.values.length === 0) {
                        continue;
                    }*/
                    let value = _.get(_.find(item.values, {
                        type,
                    }), 'value');
                    if (value !== undefined) {
                        _.each(_.range(linesCount), (index) => {
                            if (value === index) {
                                linesData.push({
                                    t1: moment(item.time).unix(),
                                    t2: moment(nextItem.time).unix(),
                                    color: colors[index],
                                    name: names[index],
                                });
                            }
                        });
                    }
                }

                const boolData = _.map(this.state.data, (item) => {
                    const timestamp = moment(item.time).unix();

                    let result = {
                        timestamp,
                    };

                    _.each(linesData, (lineItem, lineIndex) => {
                        if ((lineItem.t1 === timestamp) || (lineItem.t2 === timestamp)) {
                            result[`line${lineIndex}`] = 0;
                        } else {
                            result[`line${lineIndex}`] = null;
                        }
                    });

                    return result;
                });

                return (
                    <ResponsiveContainer width="100%" height="88%">
                        <LineChart data={boolData}>
                            <XAxis dataKey="timestamp" type="number" domain={['dataMin', 'dataMax']}
                                   tickFormatter={(tick) => {
                                       return '';
                                   }}/>
                            <RechartTooltip content={<MapRetrospectiveTooltip linesData={linesData}/>}
                                            labelFormatter={(label) => {
                                                return moment.unix(label).format(formats.DATETIME_SHORT);
                                            }}/>
                            {_.map(linesData, (lineItem, index) => {
                                return (
                                    <Line key={index} dot={false} isAnimationActive={false}
                                          dataKey={`line${index}`}
                                          stroke={lineItem.color}
                                          strokeWidth={20}
                                          strokeOpacity={0.5}
                                          name={title} type="monotone"/>
                                );
                            })}
                        </LineChart>
                    </ResponsiveContainer>
                );
        }

        return null;
    }

    renderIdlePeriods() {
        let idleStartTime = moment(this.props.from);
        let prevTime = moment(this.props.from);

        let periods = [];

        _.each(this.state.data, (dataItem) => {
            const itemTime = moment(dataItem.time);
            const hasItem = dataItem.values.length > 0;
            if (hasItem && idleStartTime) {
                if (itemTime.diff(idleStartTime, 'minutes') >= this.state.time_gap_limit) {
                    periods.push({
                        from: idleStartTime.format(formats.DATETIME_API),
                        to: itemTime.format(formats.DATETIME_API),
                    });
                }
                idleStartTime = null;
            } else if (!hasItem && !idleStartTime) {
                idleStartTime = moment(itemTime);
            }
            prevTime = moment(itemTime);
        });

        if (idleStartTime) {
            if (this.props.to.diff(idleStartTime, 'minutes') >= this.state.time_gap_limit) {
                periods.push({
                    from: idleStartTime.format(formats.DATETIME_API),
                    to: this.props.to.format(formats.DATETIME_API),
                });
            }
        }

        return _.map(periods, ::this.renderIdlePeriod);
    }

    renderIdlePeriod(period, index) {
        const length = this.props.to.diff(this.props.from, 'seconds');
        const from = moment(period.from).diff(this.props.from, 'seconds');
        const to = moment(period.to).diff(this.props.from, 'seconds');
        const _left = (100 * from / length);
        const _right = (100 - 100 * to / length);

        return (
            <div key={index} className="history-idle" title="Отсутствуют данные телематики" style={{
                left: _left + '%',
                right: _right > 0 ? _right + '%' : 0,
            }}/>
        );
    }

    renderCurrentTime() {
        const length = this.props.to.diff(this.props.from, 'seconds');
        const from = moment(this.props.currentTime).diff(this.props.from, 'seconds');

        return (
            <div className="history-current-time" style={{
                left: (100 * from / length) + '%',
            }}/>
        );
    }

    onConfigChange(field, {target: {value}}) {
        let config = this.state.config;
        config[field] = value ? defaultConfig[field] : GRAPHIC_TYPE_HIDDEN;
        this.setState({config});
    }

    onConfigRadioChange(field, {target: {value}}) {
        let config = this.state.config;
        config[field] = value.replace(field, '');
        this.setState({config});
    }

    renderConfig() {
        if (!this.state.configActive) {
            return;
        }

        return (
            <div className="history-popup">
                <div className="history-popup__inner">
                    <div className="history-popup__table-wrap">
                        <table className="history-popup__table">
                            <tbody>
                            <tr>
                                <td colSpan="5">
                                    <div className="history-popup__category">Базовая телеметрия и телематика</div>
                                </td>
                            </tr>
                            {this.props.historyType !== 'cleanup_specialist' ? (
                                <tr>
                                    <td className="bold left-title" colSpan="3">Скорость</td>
                                    <td colSpan="2">
                                        <Checkbox
                                            checked={this.state.config.speed !== GRAPHIC_TYPE_HIDDEN}
                                            onChange={this.onConfigChange.bind(this, 'speed')}
                                        />
                                    </td>
                                </tr>
                            ) : null}
                            {this.props.historyType !== 'cleanup_specialist' ? (
                                <tr>
                                    <td className="bold left-title" colSpan="3">Зажигание</td>
                                    <td colSpan="2">
                                        <Checkbox
                                            checked={this.state.config.ignition !== GRAPHIC_TYPE_HIDDEN}
                                            onChange={this.onConfigChange.bind(this, 'ignition')}
                                        />
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                <td className="bold left-title" colSpan="3">Сигнал GPS/ГЛОНАС</td>
                                <td colSpan="2">
                                    <Checkbox
                                        checked={this.state.config.satellites !== GRAPHIC_TYPE_HIDDEN}
                                        onChange={this.onConfigChange.bind(this, 'satellites')}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="bold left-title" colSpan="3">Телематика</td>
                                <td colSpan="2">
                                    <div className="telematics-time__wrap">
                                        <Checkbox
                                            checked={this.state.config.telematics !== GRAPHIC_TYPE_HIDDEN}
                                            onChange={this.onConfigChange.bind(this, 'telematics')}
                                        />
                                        <Input
                                            size="md"
                                            type="number"
                                            value={this.state.telematics_time}
                                            onChange={async ({target: {value}}) => {
                                                await this.setState({
                                                    telematics_time: value,
                                                });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {this.props.historyType !== 'cleanup_specialist' ? (
                                <tr>
                                    <td colSpan="5">
                                        <div className="history-popup__category">Работа оборудования</div>
                                    </td>
                                </tr>
                            ) : null}
                            {this.props.historyType !== 'cleanup_specialist' ? (
                                ((this.state.ports || []).length > 0) ? (
                                    <tr>
                                        <th></th>
                                        <th>Градиент</th>
                                        <th>Уровень</th>
                                        <th>Скрыть</th>
                                        <th>Дискретный</th>
                                    </tr>
                                ) : (
                                    <tr>
                                        <th colSpan={5}>Порты БНСО не настроены</th>
                                    </tr>
                                )
                            ) : null}
                            {_.map(this.state.ports, (port, index) => {
                                if (!port.bnso_indicator_uuid) {
                                    return null;
                                }

                                let types = [
                                    GRAPHIC_TYPE_HIDDEN,
                                ];

                                if (port.type !== 'numeric') {
                                    types.push(GRAPHIC_TYPE_DISCRETE);
                                } else {
                                    if (_.get(port, 'graduation.spreadsheets', []).length > 0) {
                                        types.push(GRAPHIC_TYPE_DISCRETE);
                                    } else {
                                        types.push(GRAPHIC_TYPE_GRADIENT);
                                        types.push(GRAPHIC_TYPE_LEVEL);
                                    }
                                }

                                return (
                                    <tr key={index}>
                                        <td className="bold left-title">{_.get(_.get(this.props.bnsoIndicators, port.bnso_indicator_uuid), 'name')}</td>
                                        <td>
                                            {(_.indexOf(types, GRAPHIC_TYPE_GRADIENT) !== -1) ? (
                                                <Radio
                                                    checked={this.state.config[port.bnso_indicator_uuid] === GRAPHIC_TYPE_GRADIENT}
                                                    field={port.bnso_indicator_uuid}
                                                    value={port.bnso_indicator_uuid + GRAPHIC_TYPE_GRADIENT}
                                                    label="Градиент"
                                                    onChange={this.onConfigRadioChange.bind(this, port.bnso_indicator_uuid)}
                                                />
                                            ) : null}
                                        </td>
                                        <td>
                                            {(_.indexOf(types, GRAPHIC_TYPE_LEVEL) !== -1) ? (
                                                <Radio
                                                    checked={this.state.config[port.bnso_indicator_uuid] === GRAPHIC_TYPE_LEVEL}
                                                    field={port.bnso_indicator_uuid}
                                                    value={port.bnso_indicator_uuid + GRAPHIC_TYPE_LEVEL}
                                                    label="Уровень"
                                                    onChange={this.onConfigRadioChange.bind(this, port.bnso_indicator_uuid)}
                                                />
                                            ) : null}
                                        </td>
                                        <td>
                                            {(_.indexOf(types, GRAPHIC_TYPE_HIDDEN) !== -1) ? (
                                                <Radio
                                                    checked={this.state.config[port.bnso_indicator_uuid] === GRAPHIC_TYPE_HIDDEN}
                                                    field={port.bnso_indicator_uuid}
                                                    value={port.bnso_indicator_uuid + GRAPHIC_TYPE_HIDDEN}
                                                    label="Скрыть"
                                                    onChange={this.onConfigRadioChange.bind(this, port.bnso_indicator_uuid)}
                                                />
                                            ) : null}
                                        </td>
                                        <td>
                                            {(_.indexOf(types, GRAPHIC_TYPE_DISCRETE) !== -1) ? (
                                                <Radio
                                                    checked={this.state.config[port.bnso_indicator_uuid] === GRAPHIC_TYPE_DISCRETE}
                                                    field={port.bnso_indicator_uuid}
                                                    value={port.bnso_indicator_uuid + GRAPHIC_TYPE_DISCRETE}
                                                    label="Дискретный"
                                                    onChange={this.onConfigRadioChange.bind(this, port.bnso_indicator_uuid)}
                                                />
                                            ) : null}
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td colSpan="5">
                                    <div className="history-popup__category">Представление графиков</div>
                                </td>
                            </tr>
                            <tr>
                                <td className="bold left-title" colSpan="3">Отображение разрыва в поступлении данных,
                                    мин
                                </td>
                                <td colSpan="2">
                                    <div className="time-gap__wrap">
                                        <Input
                                            size="md"
                                            type="number"
                                            value={this.state.time_gap_limit}
                                            onChange={({target: {value}}) => {
                                                this.setState({
                                                    time_gap_limit: value,
                                                });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="bold left-title" colSpan="3">Количество точек диапазона</td>
                                <td colSpan="2">
                                    <div className="time-gap__wrap">
                                        <Input
                                            size="md"
                                            type="number"
                                            value={this.state.graphic_step}
                                            onChange={async ({target: {value}}) => {
                                                await this.setState({
                                                    graphic_step: value,
                                                });
                                            }}
                                        />
                                        <a className="b-button b-button_size_md b-button_red"
                                           type="button" onClick={() => {
                                            this.loadData(this.props);
                                        }}>Применить</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="bold left-title" colSpan="3">Частичная закраска</td>
                                <td colSpan="2">
                                    <div className="time-gap__wrap">
                                        <ShowDeleted
                                            active={this.state.mapDisplayParts}
                                            onChange={::this.toggleMapDisplayParts}
                                        />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    async toggleMapDisplayParts() {
        await this.setState({
            mapDisplayParts: !this.state.mapDisplayParts,
        });

        if (this.state.currentTask && this.state.currentItem) {
            this.onTaskItemClick(this.state.currentTask, this.state.currentItem);
        }

        this.props.onUpdate();
    }

    onTaskItemClick(task, item) {
        const time = moment(moment(task.date).format(formats.DATE_URL) + ' ' + (item.fact_date_from || item.date_from));
        this.props.onCurrentTimeChange(this.timeToInt(time));

        let items = [
            {
                geojson: item.geojson,
                color: this.getLineColor(item),
            },
        ];

        if (this.state.mapDisplayParts) {
            _.each(item.confirmed_parts || [], (confirmedPart, _index) => {
                items.push({
                    geojson: confirmedPart,
                    color: 'green',
                });
            });
        }

        this.props.onGeojsonChange(items);

        this.setState({
            currentTask: task,
            currentItem: item,
        });
    }

    onTaskItemViolationClick(task, violation) {
        const time = moment(violation.data.time);
        this.props.onCurrentTimeChange(this.timeToInt(time));
    }

    getLineColor(item) {
        if (this.state.mapDisplayParts) {
            return item.is_confirmed ? 'yellow' : '#c400f6';
        }
        return item.is_confirmed ? 'green' : 'red';
    }

    async onOrderExecutionClick(orderExecution) {
        const time = moment(orderExecution.date_start);
        this.props.onCurrentTimeChange(this.timeToInt(time));
        this.props.onGeojsonChange(await this.loadRouteVariant(orderExecution.route_variant_uuid));
    }

    async loadRouteVariant(uuid) {
        const response = await this.props.getRouteVariant(uuid);

        if (response.isOk) {
            const route = response.payload;
            let coordinates = [];
            _.each(_.concat(route.forward_points, route.reverse_points), (point) => {
                coordinates = _.concat(coordinates, _.get(point, 'path_to_the_next_point_geometry.coordinates', []));
            });
            return {
                type: 'LineString',
                coordinates,
            };
        } else {
            response.showErrors();
        }
    }

    renderVisitsIntersection(timeFrom, timeTo, visits, redColor) {
        if (flag) return;
        flag = true;
        const length = this.props.to.diff(this.props.from, 'seconds');
        return _.map(visits, (visit, index) => {
            let from = moment(visit.date_from);
            let to = moment(visit.date_to);
            if (!(from.isSameOrBefore(timeTo) && to.isSameOrAfter(timeFrom))) {
                return null;
            }

            from = from.isBefore(timeFrom) ? timeFrom : from;
            to = to.isAfter(timeTo) ? timeTo : to;

            const left = from.diff(this.props.from, 'seconds');
            const right = to.diff(this.props.from, 'seconds');

            return (
                <div key={`visit-intersection-${index}`} className="history-graphic_road-item"
                     style={{
                         left: (100 * left / length) + '%',
                         right: (100 - 100 * right / length) + '%',
                         backgroundColor: redColor,
                     }}/>
            );
        });
    }

    renderTasks() {
        if (this.props.component !== 'road') {
            return;
        }

        const length = this.props.to.diff(this.props.from, 'seconds');

        return _.map(this.props.tasks, (task) => {
            const violations = _.filter(this.props.taskViolations, {task_uuid: task.uuid});
            const visits = _.filter(this.props.objectVisits, {task_uuid: task.uuid});

            return (
                <div key={task.uuid} className="history-graphic">
                    <div className="history-graphic_title">
                        <Link to={`/road/tasks/${task.uuid}`} target="_blank">Задание №{task.number}</Link>
                    </div>
                    <div className="history-graphic_body">
                        <div className="history-graphic_road-visits"/>
                        <div className="history-graphic_road">
                            {_.map(task.items_fact || task.items, (item, index) => {
                                if (!task.date || !(item.fact_date_from || item.date_from) || !(item.fact_date_to || item.date_to)) {
                                    return;
                                }

                                let isSelected = false;
                                if (_.isArray(this.props.historyGeojson)) {
                                    const selectedIndex = _.get(_.first(this.props.historyGeojson), 'index');
                                    const selectedTask = _.get(_.first(this.props.historyGeojson), 'task');
                                    isSelected = (selectedIndex === index) && (selectedTask.uuid === task.uuid);
                                }

                                const redColor = isSelected ? '#800000' : 'red';
                                const greenColor = isSelected ? '#008A00' : 'green';

                                const from = moment(moment(task.date).format(formats.DATE_URL) + ' ' + (item.fact_date_from || item.date_from));
                                const to = moment(moment(task.date).format(formats.DATE_URL) + ' ' + (item.fact_date_to || item.date_to));

                                const left = from.diff(this.props.from, 'seconds');
                                const right = to.diff(this.props.from, 'seconds');

                                const workType = _.get(_.get(this.props.workTypes, item.work_type_uuid), 'name');
                                const tooltip = `№${index + 1} ${workType} ${from.format(formats.TIME_FULL)} - ${to.format(formats.TIME_FULL)}`;

                                return [
                                    this.renderVisitsIntersection(from, to, visits, redColor),
                                    <div key={index} className="history-graphic_road-item"
                                         onClick={this.onTaskItemClick.bind(this, task, item)}
                                         style={{
                                             left: (100 * left / length) + '%',
                                             right: (100 - 100 * right / length) + '%',
                                         }}>
                                        <ContextTooltip default={tooltip} position="top">
                                            <div className="history-graphic_road-item_left"
                                                 style={{
                                                     backgroundColor: item.is_confirmed ? greenColor : redColor,
                                                 }}/>
                                        </ContextTooltip>
                                        <ContextTooltip default={tooltip} position="top">
                                            <div className="history-graphic_road-item_right"
                                                 style={{
                                                     backgroundColor: item.is_confirmed ? greenColor : redColor,
                                                 }}/>
                                        </ContextTooltip>
                                    </div>,
                                    _.map(_.get(item, 'debug.checked_by') || [], (checkedBy, index2) => {
                                        const from = moment(checkedBy.first[2], formats.DATETIME);
                                        const to = moment(checkedBy.second[2], formats.DATETIME);

                                        const left = from.diff(this.props.from, 'seconds');
                                        const right = to.diff(this.props.from, 'seconds');

                                        return (
                                            <div key={`${index}:${index2}`} className="history-graphic_road-check"
                                                 style={{
                                                     left: (100 * left / length) + '%',
                                                     right: (100 - 100 * right / length) + '%',
                                                     backgroundColor: greenColor,
                                                 }}>
                                            </div>
                                        );
                                    }),
                                ];
                            })}
                            {/* {_.map(visits, (visit, index) => {
                                const from = moment(visit.date_from);
                                const to = moment(visit.date_to);
                                const item = _.get(task.items_fact, visit.task_item_index);
                                if (!item) {
                                    return null;
                                }

                                const left = from.diff(this.props.from, 'seconds');
                                const right = to.diff(this.props.from, 'seconds');

                                const related = this.state.related.getItem(_.get(item, 'geometry.0.item_uuid'));
                                const tooltip = `№${index + 1} ${_.get(related, 'name', '...')} ${from.format(formats.TIME_FULL)} - ${to.format(formats.TIME_FULL)}`;

                                return (
                                    <div key={index} className="history-graphic_road-visit-item"
                                         onClick={this.onTaskItemClick.bind(this, task, item)}
                                         style={{
                                             left: (100 * left / length) + '%',
                                             right: (100 - 100 * right / length) + '%',
                                             backgroundColor: 'blue',
                                         }}>
                                        <ContextTooltip default={tooltip} position="top">
                                            <div className="history-graphic_road-item_left"
                                                 style={{
                                                     backgroundColor: 'blue',
                                                 }}/>
                                        </ContextTooltip>
                                        <ContextTooltip default={tooltip} position="top">
                                            <div className="history-graphic_road-item_right"
                                                 style={{
                                                     backgroundColor: 'blue',
                                                 }}/>
                                        </ContextTooltip>
                                    </div>
                                );
                            })}*/}
                            {_.map(violations, (violation) => {
                                const from = moment(violation.data.time);

                                const left = from.diff(this.props.from, 'seconds');
                                let tooltip = '';
                                switch (violation.type) {
                                    case 'max_speed':
                                        tooltip = `Превышение скорости ${violation.data ? `(${moment(violation.data.time).format(formats.TIME_FULL)}, ${violation.data.latitude}, ${violation.data.longitude})` : ''})`;
                                        break;
                                    case 'mechanism':
                                        tooltip = `Не включен механизм "${this.getMechanismName(violation.mechanism_binding_uuid)}" ${violation.data ? `(${moment(violation.data.time).format(formats.TIME_FULL)}, ${violation.data.latitude}, ${violation.data.longitude})` : ''})`;
                                        break;
                                    case 'route_fail':
                                        tooltip = `Отклонение от маршрута (${violation.start_at} км)`;
                                        break;
                                }

                                return (
                                    <ContextTooltip key={violation.uuid} default={tooltip} position="top">
                                        <div key={violation.uuid} className="history-graphic_road-violation"
                                             onClick={this.onTaskItemViolationClick.bind(this, task, violation)}
                                             style={{
                                                 left: (100 * left / length) + '%',
                                             }}>
                                        </div>
                                    </ContextTooltip>
                                )
                            })}
                        </div>
                    </div>
                </div>
            );
        });
    }

    getMechanismName(mechanismBindingUuid) {
        const mechanismTypeUuid = _.get(_.get(this.props.kurs_mechanism_bindings, mechanismBindingUuid), 'mechanism_type_uuid');
        return _.get(_.get(this.props.kurs_mechanism_types, mechanismTypeUuid), 'name');
    }

    onFactToggleChange({target: {value}}) {
        this.setState({
            factToggle: value === '1',
        });
    }

    renderOrderExecutions() {
        if (_.indexOf(['kiutr', 'ems'], this.props.component) === -1) {
            return;
        }

        const length = this.props.to.diff(this.props.from, 'minutes');

        const isFact = this.state.factToggle ? 0 : 1;

        return (
            <div className="history-graphic">
                <div className="history-graphic_title">
                    <div className="history-graphic__metadata">
                        <div className="b-block__text">
                            <Radio
                                checked={!isFact}
                                field="is_fact"
                                value={'1'}
                                label="План"
                                onChange={this.onFactToggleChange.bind(this)}
                            />
                            <br/>
                            <Radio
                                checked={!!isFact}
                                field="is_fact"
                                value={'0'}
                                label="Факт"
                                onChange={this.onFactToggleChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className="history-graphic_body">
                    <div className="history-graphics_kiutr">
                        {_.map(this.props.orderExecutions, (orderExecution) => {
                            const fromRun = moment(orderExecution.date_start);
                            const toRun = moment(orderExecution.date_end);

                            const leftRun = fromRun.diff(this.props.from, 'minutes');
                            const rightRun = toRun.diff(this.props.from, 'minutes');

                            const lengthRun = toRun.diff(fromRun, 'minutes');

                            return (
                                <div key={orderExecution.uuid} className="history-graphic_kiutr"
                                     onClick={this.onOrderExecutionClick.bind(this, orderExecution)}
                                     style={{
                                         left: (100 * leftRun / length) + '%',
                                         right: (100 - 100 * rightRun / length) + '%',
                                     }}>
                                    {_.map(orderExecution.data, (item, index) => {
                                        if (item.point_type !== 'stop_point') {
                                            return;
                                        }

                                        const fromStr = isFact ? item.time_fact : item.time_plan;
                                        if (!fromStr) {
                                            return;
                                        }
                                        const from = moment(fromStr);

                                        const left = from.diff(fromRun, 'minutes');

                                        const tooltip = `${this.state.related.get(item.type_uuid)} - ${from.format(formats.TIME)}`;

                                        let backgroundColor = 'gray';
                                        if (isFact) {
                                            if (item.is_in_time) {
                                                backgroundColor = 'green';
                                            } else if (item.is_after_time || item.is_before_time) {
                                                backgroundColor = 'yellow';
                                            } else if (item.is_skipped) {
                                                backgroundColor = 'red';
                                            }
                                        }

                                        return (
                                            <div key={index} className="history-graphic_kiutr-item" style={{
                                                left: (100 * left / lengthRun) + '%',
                                            }}>
                                                <ContextTooltip default={tooltip} position="top">
                                                    <div className="history-graphic_kiutr-item_marker"
                                                         style={{
                                                             backgroundColor,
                                                         }}/>
                                                </ContextTooltip>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    renderMileage() {
        return (
            <div className="history-graphic">
                <div className="history-graphic_title">
                    <div className="history-graphic__metadata">
                        <div className="b-block__text">
                            Пробег (км)
                        </div>
                    </div>
                </div>
                <div className="history-graphic_body">
                    <div className="history-graphics_mileage">
                        {window.RNIS_SETTINGS.distance_in_history_player_grpc ? ((this.state.mileage >= 0) ? (this.state.mileage).toFixed(1) : null) : null}
                        {window.RNIS_SETTINGS.distance_in_history_player ? ((this.state.mileageTo >= 0 && this.state.mileageFrom >=0) ? (this.state.mileageTo - this.state.mileageFrom).toFixed(1) : null) : null}
                    </div>
                </div>
            </div>
        );
    }
}